
import styled from "styled-components";
// import blackLineImg from "../../images/black-line.png";
// import logoImg from "../../images/logo.png";
// import circleBgImg from "../../images/circle-bg.png";
// import blueCardImg from "../../images/blue-card.png";


const blackLineImg = "https://dashboard.tap1ce.com/media/black-line.png";
const logoImg = "https://dashboard.tap1ce.com/media/logo.png";
const circleBgImg =  "https://dashboard.tap1ce.com/media/circle-bg.png";
const blueCardImg = "https://dashboard.tap1ce.com/media/blue-card.png";


export const HomeContainer = styled.div`


.main-wrapper {
  height: 100vh;  
}

.full-section {
  height: 100%;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.half-section {
  background: #f9f9f9;
  height: 50%;  
  width: 100%;
  margin: 15px 0;
}


#object {
  position: absolute;
  transform: translateX(0px);
  transition: all 1s;
}

.moving_object{
  max-width: 440px;
  border-radius: 15px;
}


.bg-white{
  background: #FFFFFF;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.06);
}

.main-title{
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 55px;
    /* or 154% */
    text-transform: uppercase;
}

.main-title span{
  background: #17556D;
  color:#fff;
  border-radius: 3px;
  padding-left:10px;
  padding-right:10px;
  padding-top:0px;
  padding-bottom:0px;
}

.main-title-light{
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  line-height: 55px;
  /* or 154% */
  text-transform: uppercase;
}

.main-title-light span{
background: rgba(23, 85, 109, 0.05);
color:#17556D;
border-radius: 3px;
padding-left:10px;
padding-right:10px;
padding-top:0px;
padding-bottom:0px;
}


.btn-white{
  background:#fff;
  color:#000;
  border-radius: 0px;
}

.btn-outline-white{
  border-color: #fff;
  color:#fff;
  border-radius: 0px;
}


.black-title{
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  text-transform: uppercase;
  text-align: center;
  color: #1A1A1A;
  background-position: 50% 1.4em;
  background-size: 200px 2px;
  background-repeat: no-repeat;
  background-image:url(${blackLineImg});
}


.logo-bg-wrap {
  position: relative;
}

.logo-bg-wrap:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 10%;
  top: 0;
  width: 75%;
  height: 100%;
  opacity: 0.05;
  background-image:url(${logoImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.logo-bg-content {
  position: relative;
}

.logo-bg-content a {
display: flex;
align-items: center;
justify-content: center;
}

.logo-bg-content a i.video {
  position:absolute;
  height: 50px;
  width: 50px;
  background: #ffffff;
  display: inline-block;
  font-size: 25px;
  color: #17556D;
  text-align: center;
  line-height: 50px;
  border-radius: 100%;
}
}


.circle-bg-wrap {
  position: relative;
  padding:50px;
}

.circle-bg-wrap:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 15%;
    top: 25%;
    width: 50%;
    height: 50%;
    background-image:url(${circleBgImg});
    background-repeat: no-repeat;
    background-size: contain;

}

.circle-bg-content {
  position: relative;
}

.circle-bg-content .title{
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 45px;
  color: #1A1A1A;
}

.circle-bg-content p{
  color: #1A1A1A;
  font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 30px;
}

.marquee-title{
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  text-align: center;
  line-height: 45px;
  color: #1A1A1A;
}

.marquee-sub-title{
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 45px;
  text-align: center;
  color: #17556D;
}

.marquee {
   display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.marquee-wrap {
  width:100%;
  position:absolute;
  overflow: hidden;
  margin-left: -20px;
}

.marquee-content{
  display:flex;
  list-style:none;
  animation: scrolling 30s linear infinite;
}

.marquee-content-forward{
  display:flex;
  list-style:none;
  animation: scrolling-reverse 30s linear infinite;
}

.marquee-item{
  height:150px;
  flex-shrink:0;
  margin:10px;
}

.marquee-image{
  height:100%;
  width:100%;
}

@keyframes scrolling {
  0% { transform: translateX(0); }
  100% { transform: translatex(-5800px); }
}

@keyframes scrolling-reverse {
  0% { transform: translateX(-5800px); }
  100% { transform: translatex(0); }
}

.marquee-content-forward{
  display:flex;
  list-style:none;
  marquee-direction: forward;
}

.step-content{

}

.step-content .title{
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 45px;
  text-align: center;
  color: #000000;
  margin-top: -40px;
}

.step-content .sub-title{
  text-align: inherit;
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 45px;
    color: #000000;
    margin-top: -40px;
    
}

.step-content .step{
  font-style: normal;
  font-weight: 800;
  font-size: 70px;
  line-height: 80px;
  color: #000000;
  opacity:0.05;
}

.step-content .mt-n20{
  margin-top:-20%;
}


.slider-section{

}

.slick-center {
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  transform: scale(1.25);    
}

.slick-list { 
  padding:45px 60px !important;
}

.slick-arrow{
  display: block;
  position: absolute;
  top: 50%;
  height: 40px;
  width: 40px;
  transform: translateY(-50%); 
}

.slick-prev:before, .slick-next:before {
  color: #17556D;
  font-size: 50px;
}

.slick-next{
    right: 25px;
}

.slick-prev{
    left: 25px;
}

.slick-dots li.slick-active button:before {
  color: #17556D !important;
}


.slider-section .title{
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  text-align: center;
  line-height: 45px;
  color: #1A1A1A;
}

.slider-section .sub-title{
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 45px;
  text-align: center;
  color: #17556D;
}




@media only screen and (max-width: 600px) {

  .section {
    padding: 50px 0;
  }

  .black-title {
    font-size: 30px;
    background-position: 50% 1.5em;
  }
  .logo-bg-wrap:before{
    width: 100%;
    height: 100%;
    top:unset;
    left: 0%;
    bottom: -45%;
  }

  .circle-bg-wrap {
    position: relative;
    padding: 20px;
  }

  .circle-bg-content .title {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    color: #1A1A1A;
  }

  .circle-bg-content p {
    text-align: center;
    font-size: 18px;
    line-height: 25px;
  }

  .marquee-wrap {
    width:100%;
    overflow: hidden;
  }

  .marquee-center-image{
    max-width: 60%;
  }

  .step-content .title {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    text-align: center;
    color: #000000;
    margin-top: 40px;
}

.image-content img {
  max-width: 100%;
  padding: 20px;
}

.step-content .image-content img {
  max-width: 100%;
  padding: 20px;
  margin-bottom: -110px !important;
}

.step-content .image-content{
  margin-top: 50px;
}

.step-content .step {
  text-align: left!important;
}

.step-content .sub-title {
  text-align: left!important;
  text-align: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 35px;
  color: #000000;
  margin-top: -40px;
}


.main-gradient-banner::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    height: 200%;
    border-radius: 0%;
    background-image: linear-gradient(
45deg
, #0e1033 0%, #0e1033 20%, #faaf43 50%);
}



.bg-1 {
  background: url(https://dashboard.tap1ce.com/media/video-banner.png) fixed no-repeat;
  background-size: cover;
}


/* Inspired from:
http://stackoverflow.com/questions/25573562/wrapping-a-youtube-video-in-a-static-image-frame-and-maintain-responsive-resiz */
/* div.mobile-wrapper {
    position: relative;
    padding-top: 25px;
    padding-bottom: 67.5%;
    height: 0;
}
div.mobile-wrapper iframe {
    box-sizing: border-box;
    background: url(https://juliamenndez.com/wp-content/uploads/mobile-frame.png) center center no-repeat;
    background-size: contain;
    padding: 7% 38.4% 12.8%;;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

div.mobile-wrapper video {
    box-sizing: border-box;
    background: url(https://dashboard.tap1ce.com/media/mobile-frame.png) center center no-repeat;
    background-size: contain;
    padding: 7% 38.4% 12.8%;;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.mobile-phone {
    margin: auto;
    margin-top: 170px;
    padding: 10px 10px 30px;
    width: 350px;
    height: 600px;
    box-shadow: 0 0 20px #e2e2e2;
    border-radius: 30px;
    background: url(https://dashboard.tap1ce.com/media/mobile-frame.png) center center no-repeat;
    background-size: contain;
  }
  
  .screen {
    width: 100%;
    height: 100%;
    background: #f2f2f2;
    border-radius: 30px;
    overflow-y: auto;
    overflow: hidden;
    
  }
  .screen video{
    width: inherit;
    height: fit-content;
}

.s1{
   
    width: 100%;
    height: 100%;
    border-radius: 30px;
    overflow-y: auto;
    overflow: hidden;
}

.s1 video{
    width: inherit;
    height: fit-content;
}
  
  .brove {
    width: 150px;
    height: 20px;
    background: white;
    position: absolute;
    margin: 0 100px;
    border-radius: 0 0 20px 20px;
  }
  
  .speaker {
    width: 60px;
    height: 5px;
    background: #d2d2d2;
    display: block;
    margin: auto;
    margin-top: 5px;
    border-radius: 20px;
  }
 */



  .frame {
    position: relative;
    text-align: center;
  }
  
  .frame-minimal-phone {
    border-radius: 24px;
    max-width: 360px;
    margin: 0 auto;
    padding: 8px;
    padding-top: 20px;
    background-image: linear-gradient(to right, #434343 0%, black 100%);
    box-shadow: 0px 8px 16px 0px rgba(18, 1, 64, 0.1);
  }
  .frame-minimal-phone::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 50%;
    margin-left: -4px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: rgba(127, 127, 127, 0.25);
  }
  .frame-minimal-phone img {
    border-radius: 16px;
    max-width: 100%;
  }

  .frame-minimal-phone video {
    border-radius: 16px;
    max-width: 100%;
  }



  .mobile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: perspective 0.5s ease-in-out;
    perspective: 300px;

  }
  
  .mobile-container:hover {
    perspective: 800px;
  }
  

/*   .card-container {
    position:absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: perspective 0.5s ease-in-out;
    perspective: 300px;

  }
  
  .card-container:hover {
    perspective: 800px;
  } */

  .phone {
    width: 264px;
    height: 533px;
    padding: 10px;
    border-radius: 36px;
    background: #fff;
    box-shadow: 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%), inset 0 -2px 6px 0 rgb(10 37 64 / 55%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 16px;
    -webkit-transform: rotateX(2deg) rotateY(-3deg);
    transform: rotateX(2deg) rotateY(-3deg);
  }
  
  .screen {
    position: relative;
    height: 100%;
    border-radius: 34px;
    -webkit-mask-image: -webkit-radial-gradient(#fff,#000);
    background: #fff;
  }
  
  .screen-content {
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    position: relative;
    margin: 0;
  }

  .screen-content video {
    border-radius: 16px;
    max-width: 100%;
  }


  
  

`;
  

export const BoxContainer = styled.div`

.card{
  background: #fafafa;
  border-radius: 0px;
  border-color:#dfdfdf;
  border:1px solid rgba(0,0,0,.2);
}

.card:hover {
  transform: scale(1.05);
  background: #fff;
  box-shadow: 0 0 25px 7px #aaa;
  border:none;
}

.title{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color:#000;
}

.icon{
  font-size: 20px;
  color:#000;
}

.sub-title{
  color: #000;
  opacity: 0.7;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
}

.bg{
  position: absolute;
background: url(../../images/box-card-bg.png);
background-position:bottom right;
}

.dark-blue{
  color:#1E5A74;
  font-weight:600;
}


`;