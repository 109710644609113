import styled from "styled-components";
const blackLineImg = "https://dashboard.tap1ce.com/media/black-line.png";

export const ProductContainer = styled.div`
.homescreen {
  max-width: 1300px;
  margin: 1rem auto;
}

.section2{
  margin-top: 70px;
}

.homescreen__title {
  font-size: 1.5rem;
  color: #171717;
  margin-bottom: 1rem;
  margin-left: 8px;
}

.homescreen__products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.black-title{
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  text-align: center;
  color: #1A1A1A;
  background-position: 50% 1.4em;
  background-size: 200px 2px;
  background-repeat: no-repeat;
}

.black-title span{
  color:#b1d4e0;
  border-bottom: 4px solid #b1d4e0;
}

@media (max-width: 1232px) {
  .homescreen__products {
    grid-template-columns: repeat(3, auto);
  }
}

@media (max-width: 950px) {
  .homescreen__products {
    grid-template-columns: repeat(2, auto);
  }
}

@media (max-width: 630px) {
  .homescreen__products {
    grid-template-columns: 1fr;
  }
}
`;