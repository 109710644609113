const themeList = [
  {
    name: "simple",
    title: "Simple",
  },  
  {
      name: "light",
      title: "Light",
    },
    {
      name: "dark",
      title: "Dark",
    },
    {
      name: "sbi",
      title: "SBI Bank",
    },

  ];

  export default themeList