import { SingleCardContainer } from "./style.js";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {
  Button,
  Form,
  Row,
  Col,
  Spinner,
  FloatingLabel,
  Card,
} from "react-bootstrap";
//import { Nav,Tab,Tabs,TabContainer,TabContent,TabPane} from "react-bootstrap";
import { Link } from "react-router-dom";

import profileTypeList from "../../utils/profile-type";

import qrBG from "../../images/profile-bg-1.png";
//import cardBG2 from "../../images/profile-bg-2.png";

// Actions
import {
  getCardDetails,
  getAllProfiles,
  activeProfile,
  resetActiveProfile,
  setCardUsername,
} from "../../redux/actions/cardActions";

import Slider from "react-slick";
import { toast } from "react-toastify";

// Actions
// Actions
import { getUser } from "../../redux/actions/authActions";
import { useAuth } from "../../providers/use-auth.js";

import axiosInstance from "../../services/AxiosInstance";

import mergeImages from "merge-images";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import { CopyToClipboard } from "react-copy-to-clipboard";

const cardBG = "https://dashboard.tap1ce.com/media/profile-bg-1.png";
const cardBG2 = "https://dashboard.tap1ce.com/media/profile-bg-2.png";

const SingleCardPage = ({ match, history }) => {
  const [progress, setProgress] = useState(false);
  const [name, setName] = useState("");
  //const [card, setCard] = useState("");

  const [qr, setQR] = useState("");
  const [copied, setCopied] = useState(false);

  const [addProfile, setAddProfile] = useState(true);

  const [username, setUsername] = useState("");

  const dispatch = useDispatch();

  function profileData(profileType) {
    const profileTypeIndex = profileTypeList.findIndex(
      (x) => x.type === profileType
    );
    if (profileTypeIndex != -1) {
      return profileTypeList[profileTypeIndex];
      // if (profileTypeIndex == 6) {
      //   return profileTypeList[0];
      // } else {
      //   return profileTypeList[profileTypeIndex];
      // }
    } else {
      return profileTypeList[0];
    }
  }

  const [activeProfileID, setActiveProfileID] = useState("");

  const cardDetailsSelector = useSelector((state) => state.getCardDetails);
  const { cardLoading, cardError, card } = cardDetailsSelector;

  //get all profiles
  const allProfilesSelector = useSelector((state) => state.getAllProfile);
  const { allProfileLoading, allProfileError, profiles } = allProfilesSelector;

  const activeProfileSelector = useSelector((state) => state.activeProfile);
  const { activeProfileLoading, activeProfileError, isProfileActivated } =
    activeProfileSelector;

  const userSelector = useSelector((state) => state.auth);
  const { auth } = userSelector;

  const fireAuth = useAuth();

  useEffect(() => {
    if (auth && fireAuth.user) {
      setQR(
        "http://api.qrserver.com/v1/create-qr-code/?data=" +
          encodeURI("https://profile.tap1ce.com/" + match.params.id) +
          "&size=100x100&bgcolor=ffffff"
      );
      dispatch(getCardDetails(match.params.id));
    }
  }, [fireAuth.user]);

  useEffect(() => {
    if (card) {
      if (card.username) {
        setUsername(card.username ?? "");
      } else {
        setUsername("");
      }

      if (card.name) {
        setName(card.name);
      } else {
        setName("");
      }

      //load profiles
      dispatch(getAllProfiles(match.params.id));

      if (card.activeProfileId != null) {
        setActiveProfileID(card.activeProfileId);
      }
    }
  }, [card]);

  //on load profiles
  useEffect(() => {
    if (profiles) {
      if (card) {
        if (card.isCorporate) {
          setAddProfile(true);
        } else {
          if (card.product.type === "social" && profiles.length > 0) {
            setAddProfile(false);
          } else {
            setAddProfile(true);
          }
        }
      }
    }
  }, [allProfileLoading, allProfileError, profiles]);

  useEffect(() => {
    if (isProfileActivated) {
      setProgress(false);
      toast.success("profile activated!");
      dispatch(resetActiveProfile());
      history.push(`/my-account`);
    }

    if (activeProfileLoading) {
      setProgress(true);
    }

    if (activeProfileError) {
      setProgress(false);
      toast.error(activeProfileError);
    }
  }, [activeProfileLoading, activeProfileError, isProfileActivated]);

  async function onDelete(profileId) {
    setProgress(true);

    try {
      //axios.post("/api/auth/signIn",fd,config);
      const { data } = await axiosInstance.post("profiles/remove", {
        profile_id: profileId,
      });

      setProgress(false);

      console.log("delete data - " + JSON.stringify(data));

      if (data.success === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        window.location.reload();
        setTimeout(() => {
          dispatch(getAllProfiles(match.params.id));
        }, 500);
      }
    } catch (error) {
      setProgress(false);
      toast.error(error.response.data.error);
    }
  }

  async function onEdit(profileId) {
    console.log("onEdit -" + profileId);
    history.push({
      pathname: "/edit-profile/" + profileId,
      state: { cardId: match.params.id },
    });
  }

  function onProfileChange(profile) {
    setActiveProfileID(profile);
    onActiveProfile(profile);
  }

  async function onSaveUsername() {
    setProgress(true);
    if (!card) {
      setProgress(false);
      return toast.warn("Card not found!");
    }

    if (username === "") {
      setProgress(false);
      return toast.warn("Please username first.");
    }

    console.log("card Id - " + card.id + ", username - " + username);

    const result = await setCardUsername(card.id, username);

    if (result.status) {
      return toast.success(result.message);
    } else {
      return toast.error(result.message);
    }
  }

  function onActiveProfile(newProfile) {
    console.log("onActiveProfile called id - " + newProfile);

    setProgress(true);

    if (!card) {
      setProgress(false);
      return toast.warn("Card not found!");
    }

    if (newProfile === "") {
      setProgress(false);
      return toast.warn("Please select profile first.");
    }

    console.log("card Id - " + card.id + ", profile id - " + newProfile);

    return dispatch(activeProfile(card.id, newProfile, name));
  }

  function fullName(profile) {
    if (profile) {
      var name = profile.name;

      if (!(profile.namePrefix === "undefined") && profile.namePrefix) {
        name = profile.namePrefix + " " + name;
      }
      return name;
    } else {
      return "";
    }
  }

  function profileItem(profile, isEven) {
    return (
      <div className="mb-2">
        <Card>
          <Card.Img variant="top" src={isEven ? cardBG2 : cardBG} />

          <div className={"card-taps text-left"}>
            <h2
              className={
                "h6 font-weight-bold mb-0 " +
                (isEven ? "text-grey" : "text-white")
              }
            >
              {profile.profileName ?? ""}
            </h2>
          </div>

          <div
            className={
              "card-content text-left " + (isEven ? "text-grey" : "text-white")
            }
          >
            <h2
              className={
                "h6 font-weight-bold mb-0 " +
                (isEven ? "text-grey" : "text-white")
              }
            >
              <i className={profileData(profile.type).icon}></i>
              &nbsp;
              {profileData(profile.type).type === "business"
                ? fullName(profile)
                : profileData(profile.type).name}
            </h2>
            <p
              className={
                "text-small sort-link marquee " +
                (isEven ? "text-grey" : "text-white")
              }
            >
              <span>
                {profileData(profile.type).type === "business"
                  ? profile.company
                  : profile.link}
              </span>
            </p>
          </div>
        </Card>

        <div className="row py-2">
          <div className="col">
            {activeProfileID === profile.id ? (
              <Button
                to={"/cards/" + card.id}
                className="btn btn-sm btn-success btn-block btn-activated"
              >
                Active Now
              </Button>
            ) : (
              <Button
                onClick={(e) => onProfileChange(profile.id)}
                className="btn btn-sm btn-success btn-block btn-make-active"
              >
                Activate
              </Button>
            )}
          </div>

          {!profile.isCorporate ? (
            <div className="col text-right">
              <Link
                to={{
                  pathname: "/edit-profile/" + profile.id,
                  state: { cardId: match.params.id },
                }}
                className="btn btn-sm btn-primary mr-2"
              >
                Edit
              </Link>

              <Button
                onClick={(e) => onDelete(profile.id)}
                className="btn btn-sm btn-danger btn-delete"
              >
                <i className="ti-trash"></i>
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }

  async function downloadQR() {
    const { data } = await axiosInstance.get(
      "cards/generate-qr/" + match.params.id,
      {}
    );

    if (data.status) {
      console.log("base64 - " + data.b64);
      const linkSource = data.b64;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = "tap1ce-qr.png";
      downloadLink.click();
    }

    /* mergeImages([
      
      {src:cardBG,format:'image/png'}, 
      {src:"http://api.qrserver.com/v1/create-qr-code/?data="+encodeURI("https://profile.tap1ce.com/"+match.params.id)+"&size=512x512&bgcolor=ffffff",format:'image/png'}
    
    ],{
      width: 1024,
      height: 1024,
      crossOrigin: "Anonymous",
    })
    .then(b64 => {
      console.log("base64 - "+b64);
      const linkSource = 'data:image/png;base64'+b64;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = "tap1ce-qr.png";
      downloadLink.click();
    }); */
  }

  function shareUrl() {
    if (card) {
      if (card.username) {
        return "https://profile.tap1ce.com/" + card.username;
      }
    }
    return "https://profile.tap1ce.com/" + match.params.id;
  }

  return (
    <SingleCardContainer>
      <section className="section">

     

        <div className="container">

        <Breadcrumb>
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item active>Profiles</Breadcrumb.Item>
    </Breadcrumb>

          {cardLoading || allProfileLoading ? (
            <div className="loader-box">
              <div className="col text-center">
                <div className="loader"></div>
              </div>
            </div>
          ) : cardError ? (
            <h2>{cardError}</h2>
          ) : (
            <>



              <div className="page-title-section">

             

                <div className="row">
                  <div className="col-lg-4">
                    <h3 className="title">Card Profile</h3>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <small>https://profile.tap1ce.com/</small>
                        </span>
                      </div>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="Username"
                        aria-label="Username"
                        aria-describedby="basic-addon2"
                        value={username}
                        pattern={/^[a-z0-9_\.]+$/}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-primary"
                          onClick={(e) => onSaveUsername()}
                          type="button"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-6">
                    {qr != "" ? (
                      <div className="text-center">
                        <div
                          className=""
                          href="#"
                          onClick={(e) => downloadQR()}
                          target="_blank"
                          download
                        >
                          <img className="img img-thumbnail" src={qr}></img>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <CopyToClipboard
                      text={shareUrl()}
                      onCopy={() => {
                        setCopied(true);
                        setTimeout(() => {
                          setCopied(false);
                        }, 1500);
                      }}
                    >
                      <div className="text-center">
                        <button className="btn btn-sm btn-light mr-2">
                          Copy link
                        </button>
                        {copied ? (
                          <span className="mt-2" style={{ color: "red" }}>
                            Copied.
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </CopyToClipboard>
                  </div>

                  <div className="col-lg-4 col-6 text-right">
                    <div className="row">
                      <div className="col-md-2 col-4 mt-2">
                        <EmailShareButton
                          url={shareUrl()}
                          body={""}
                          subject={"Tap1ce Profile"}
                        >
                          <EmailIcon size={32} round />
                        </EmailShareButton>
                      </div>

                      <div className="col-md-2 col-4 mt-2">
                        {" "}
                        <FacebookShareButton url={shareUrl()} hashtag="#tap1ce">
                          <FacebookIcon size={32} round />
                        </FacebookShareButton>
                      </div>
                      <div className="col-md-2 col-4 mt-2">
                        <LinkedinShareButton
                          url={shareUrl()}
                          title={"Tap1ce Profile"}
                        >
                          <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                      </div>
                      <div className="col-md-2 col-4 mt-2">
                        <TelegramShareButton
                          url={shareUrl()}
                          title={"Tap1ce Profile"}
                        >
                          <TelegramIcon size={32} round />
                        </TelegramShareButton>
                      </div>
                      <div className="col-md-2 col-4 mt-2">
                        <TwitterShareButton
                          url={shareUrl()}
                          title={"Tap1ce Profile"}
                        >
                          <TwitterIcon size={32} round />
                        </TwitterShareButton>
                      </div>
                      <div className="col-md-2 col-4 mt-2">
                        <WhatsappShareButton
                          url={shareUrl()}
                          title={"Tap1ce Profile"}
                        >
                          <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                      </div>
                    </div>

                    {addProfile ? (
                      <Link
                        to={"/add-profile/" + match.params.id}
                        className="btn btn-sm btn-outline-primary mt-2"
                      >
                        Add Profile
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              <section className="section">

            

                <Row className="mt-4">
                  {profiles.map((profile, i) => (
                    <Col lg={3} md={4} sm={12} key={i} className="mt-4 mt-md-0">
                      {profileItem(profile, i == 0 || i % 2 == 0)}

                      {/* 

                          <label>
                            <input
                              type="radio"
                              name="profile"
                              className="card-input-element"
                              id={profile.id}
                              checked={
                                activeProfileID === profile.id ? true : false
                              }
                              onChange={(e) => {
                                console.log("card change ", e.target.value);
                                onProfileChange(profile.id);
                              }}
                            />

                            <Row>
                              <Col>
                                <div className="float-left">
                                  <span className="check-icon"></span>
                                </div>

                                <div className="float-right">
                                  <Link
                                    onClick={(e) => onDelete(profile.id)}
                                    className="btn btn-sm btn-blue-2 mr-2 mb-2"
                                  >
                                    <i className="ti-trash"></i> Delete
                                  </Link>

                                  <Link
                                    to={{
                                      pathname: "/edit-profile/" + profile.id,
                                      
                                      state: { cardId: match.params.id },
                                    }}
                                   
                                    className="btn btn-sm btn-primary mr-2 mb-2"
                                  >
                                    <i className="ti-pencil"></i> Edit
                                  </Link>
                                </div>
                              </Col>
                            </Row>

                            <div className="action-button"></div>

                           
                            <figure className="caption-1 card-input bg-gradient-1">
                              <img
                                src={profileData(profile.type).background}
                                alt=""
                                className="w-100 card-img-top rounded effect-2"
                              />

                              <figcaption className="px-4 py-4 text-right">
                                <h2 className="h5 font-weight-bold mb-0 text-white">
                                  <i className={profileData(profile.type).icon}></i>
                                  &nbsp; {profileData(profile.type).name}
                                </h2>
                                <p className="text-small text-white marquee">
                                  <span>
                                    {profile.type === "business"
                                      ? profile.name
                                      : profile.link}
                                  </span>
                                </p>
                              </figcaption>

                              <div className="jto-top-profile-item">
                                <h6 className="h6 font-weight-bold mb-0 text-white">
                                  {profile.profileName
                                    ? profile.profileName
                                    : ""}
                                </h6>
                              </div>
                            </figure>

                           
                          </label>
 */}
                    </Col>
                  ))}
                </Row>

                <Row>
                  <Col className="col-12 text-center">
                    {profiles.length == 0 ? (
                      <>
                        <div className="title-text my-4">
                          <h3>No Profile Found</h3>

                          <p>Add your first card profile</p>
                        </div>
                      </>
                    ) : progress ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </section>
            </>
          )}
        </div>
      </section>
    </SingleCardContainer>
  );
};

export default SingleCardPage;
