import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Breadcrumb from "react-bootstrap/Breadcrumb";

import Resizer from "react-image-file-resizer";
import {
  Button,
  Form,
  Row,
  Col,
  Spinner,
  FloatingLabel,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
//import { Nav,Tab,Tabs,TabContainer,TabContent,TabPane} from "react-bootstrap";
import { Link } from "react-router-dom";

// Actions
import { addProfile, resetAddProfile } from "../../redux/actions/cardActions";

import axiosInstance from "../../services/AxiosInstance";

import Slider from "react-slick";
import { toast } from "react-toastify";

// Actions
// Actions
import { getUser } from "../../redux/actions/authActions";
import { useAuth } from "../../providers/use-auth.js";

import profileTypeList from "../../utils/profile-type";

import { AddProfileContainer } from "./style";

//import cardBG from "../../images/profile-bg-1.png";
//import cardBG2 from "../../images/profile-bg-2.png";

const cardBG = "https://dashboard.tap1ce.com/media/profile-bg-1.png";
const cardBG2 = "https://dashboard.tap1ce.com/media/profile-bg-2.png";

const AddProfilePage = ({ match, history }) => {
  //basiness profile

  const [theme, setTheme] = useState("light");

  const [profileImage, setProfileImage] = useState("");
  const [profileName, setProfileName] = useState("");

  const [prefix, setPrefix] = useState("");
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [company, setCompany] = useState("");
  const [bio, setBio] = useState("");

  const [regCompanyName, setRegCompanyName] = useState("");
  const [regCompanyAddress, setRegCompanyAddress] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [panNumber, setpanNumber] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");

  const [acType, setAcType] = useState("Current");
  const [acName, setAcName] = useState("");
  const [acNumber, setAcNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [ifscCode, setIfscCode] = useState("");

  //list
  const [websites, setWebsites] = useState([]);
  const [emails, setEmails] = useState([]);
  const [phones, setPhones] = useState([]);
  const [addresses, setAddresses] = useState([]);

  const [skype, setSkype] = useState("");
  const [hangouts, setHangouts] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [linkedinCompany, setLinkedinCompany] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");

  const [service, setService] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [profile, setProfile] = useState(null);

  //portfolio profile / spotify / instagram / linkedIn / Youtube
  const [link, setLink] = useState("");
  const [progress, setProgress] = useState(false);
  const [profileType, setProfileType] = useState(0);

  const [showBankDetails, setShowBankDetails] = useState(false);
  const [showBusinessDetails, setShowBusinessDetails] = useState(false);

  //website type
  const websiteType = ["Home", "Work"];
  const emailType = ["Home", "Work", "Other"];
  const phoneType = ["Mobile", "Home", "Work", "Other"];
  const addressType = ["Home", "Work"];

  //1Link

  const [username, setUsername] = useState("");
  const [oneLinkTitle, setOneLinkTitle] = useState("");
  const [oneLinkAbout, setOneLinkAbout] = useState("");
  const [oneLinks, setOneLinks] = useState([]);

  const [uploadedFile, setUploadedFile] = useState(null);

  const dispatch = useDispatch();

  const addProfileSelector = useSelector((state) => state.addProfile);
  const { addProfileLoading, isProfileAdded, addProfileError } =
    addProfileSelector;

  const userSelector = useSelector((state) => state.auth);
  const { auth } = userSelector;

  const fireAuth = useAuth();

  useEffect(() => {
    console.log("match card id - " + match.params.id);

    if (match.params.id) {
    } else {
      history.push({ pathname: `/my-account`, state: { menu: "cards" } });
    }
  }, [match]);

  useEffect(() => {
    if (auth && fireAuth.user) {
      setProgress(false);
      dispatch(getUser());
      loadProfileDetails();
    } else {
      //return history.push("/sign-in");
      setProgress(true);
    }
  }, [fireAuth.user]);

  const loadProfileDetails = async () => {
    const { data } = await axiosInstance.get("auth/profile");

    if (data.user) {
      setProfile(data.user);
    }
  };

  useEffect(() => {
    if (addProfileLoading) {
      setProgress(true);
    }

    if (addProfileError) {
      setProgress(false);
      toast.error(addProfileError);
    }

    if (isProfileAdded) {
      setProgress(false);
      toast.success("profile added!");
      //isProfileAdded = false;
      //history.goBack();
      dispatch(resetAddProfile());
      history.push({ pathname: "/cards/" + match.params.id });
      //history.push({ pathname: `my-account`, state: { menu: "cards" } });
    }
  }, [addProfileLoading, isProfileAdded, addProfileError]);

  const onShowMore = async (e) => {
    e.preventDefault();
    setShowMore(!showMore);
  };

  const onAddWebsite = async (e) => {
    e.preventDefault();

    if (websites.length < 5) {
      setWebsites((websites) => [
        ...websites,
        { type: websiteType[0], link: "" },
      ]);
    } else {
      toast.warn("You can't add more then 5 websites");
    }
  };

  const onRemoveWebsite = async (value) => {
    const newArray = websites.filter(function (element) {
      return element != value;
    });
    setWebsites(newArray);
  };

  const onChangeWebsiteType = async (i, type) => {
    websites[i].type = type;
    setWebsites((websites) => [...websites]);
  };

  const onChangeWebsite = async (i, link) => {
    websites[i].link = link;
    setWebsites((websites) => [...websites]);
  };

  //1 Link
  const onAdd1Link = async (e) => {
    e.preventDefault();

    if (oneLinks.length < 10) {
      setOneLinks((oneLinks) => [...oneLinks, { title: "", link: "" }]);
    } else {
      toast.warn("You can't add more then 10 links");
    }
  };

  const onRemove1Link = async (value) => {
    const newArray = oneLinks.filter(function (element) {
      return element != value;
    });
    setOneLinks(newArray);
  };

  const onChange1LinkTitle = async (i, title) => {
    oneLinks[i].title = title;
    setOneLinks((websites) => [...websites]);
  };

  const onChange1LinkUrl = async (i, link) => {
    oneLinks[i].link = link;
    setOneLinks((websites) => [...websites]);
  };

  const onAddEmail = async (e) => {
    e.preventDefault();

    if (emails.length < 5) {
      setEmails((emails) => [...emails, { type: emailType[0], email: "" }]);
    } else {
      toast.warn("You can't add more then 5 email");
    }
  };

  const onRemoveEmail = async (value) => {
    const newArray = emails.filter(function (element) {
      return element != value;
    });
    setEmails(newArray);
  };

  const onChangeEmailType = async (i, type) => {
    emails[i].type = type;
    setEmails((emails) => [...emails]);
  };

  const onChangeEmail = async (i, email) => {
    emails[i].email = email;
    setEmails((emails) => [...emails]);
  };

  const onAddPhone = async (e) => {
    e.preventDefault();

    if (phones.length < 5) {
      setPhones((phones) => [...phones, { type: phoneType[0], phone: "" }]);
    } else {
      toast.warn("You can't add more then 5 phone number");
    }
  };

  const onRemovePhone = async (value) => {
    const newArray = phones.filter(function (element) {
      return element != value;
    });
    setPhones(newArray);
  };

  const onChangePhoneType = async (i, type) => {
    phones[i].type = type;
    setPhones((phones) => [...phones]);
  };

  const onChangePhone = async (i, phone) => {
    phones[i].phone = phone;
    setPhones((phones) => [...phones]);
  };

  const onChangeProfileType = (type) => {
    setProfileType(type);
  };

  //address
  const onAddAddress = async (e) => {
    e.preventDefault();

    if (addresses.length < 5) {
      setAddresses((addresses) => [
        ...addresses,
        {
          type: addressType[0],
          street: "",
          city: "",
          state: "",
          country: "",
          zip: "",
        },
      ]);
    } else {
      toast.warn("You can't add more then 5 email");
    }
  };

  const onRemoveAddress = async (value) => {
    const newArray = addresses.filter(function (element) {
      return element != value;
    });
    setAddresses(newArray);
  };

  const onChangeAddressType = async (i, type) => {
    addresses[i].type = type;
    setAddresses((addresses) => [...addresses]);
  };

  const onChangeStreet = async (i, value) => {
    addresses[i].street = value;
    setAddresses((addresses) => [...addresses]);
  };
  const onChangeCity = async (i, value) => {
    addresses[i].city = value;
    setAddresses((addresses) => [...addresses]);
  };
  const onChangeState = async (i, value) => {
    addresses[i].state = value;
    setAddresses((addresses) => [...addresses]);
  };
  const onChangeZip = async (i, value) => {
    addresses[i].zip = value;
    setAddresses((addresses) => [...addresses]);
  };
  const onChangeCountry = async (i, value) => {
    addresses[i].country = value;
    setAddresses((addresses) => [...addresses]);
  };

  const fileChangedHandler = async (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          300,
          300,
          "PNG",
          100,
          0,
          (uri) => {
            console.log(uri);
            //this.setState({ newImage: uri });
            setProfileImage(uri);
          },
          "base64",
          200,
          200
        );
      } catch (err) {
        console.log(err);
        setProfileImage("");
      }
    }
  };

  const fileClickHandler = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const upload = (e) => {
    e.preventDefault();
    document.getElementById("selectFile").click();
  };

  function formBlock2() {
    if (profileType == 0 || profileType == 6) {
      return (
        <>
          <div className="title-text py-2">
            <h5 className="h5 font-weight-bold">Personal Information</h5>
          </div>

          <Row className="mb-3">
            <Col lg={4} className="text-center">
              <img
                onClick={upload}
                className="img img-fluid avatar"
                src={
                  profileImage != ""
                    ? profileImage
                    : "https://dashboard.tap1ce.com/media/avatar.png"
                }
              ></img>
              <input
                id="selectFile"
                type="file"
                hidden
                onChange={fileChangedHandler}
                onClick={fileClickHandler}
              />
            </Col>

            <Form.Group as={Col} lg={8}>
              <Form.Label>Profile Name</Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Profile Name"
                value={profileName}
                onChange={(e) => setProfileName(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={4} controlId="formName">
              <Form.Label>Prefix</Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Enter Prefix"
                value={prefix}
                onChange={(e) => setPrefix(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} lg={8} controlId="formPhone">
              <Form.Label className="required-field">Name</Form.Label>
              <Form.Control
                className="main"
                placeholder="Enter Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={6} controlId="formCompany">
              <Form.Label className={profileType == 0 ? "required-field" : ""}>
                Company / Organization
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Enter Company Name"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                required={profileType == 0}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="formDesignation">
              <Form.Label className={profileType == 0 ? "required-field" : ""}>
                Designation / Role / Title
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Enter Designation"
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
                required={profileType == 0}
              />
            </Form.Group>
          </Row>

          {showMore ? (
            <>
              <div className="title-text py-2">
                <h5 className="h5 font-weight-bold">Contact Information</h5>
              </div>

              <Row className="mb-3">
                <Col>
                  <Form.Label>Website</Form.Label>
                </Col>

                <Col className="text-right">
                  <Link
                    onClick={onAddWebsite}
                    className="btn btn-sm btn-blue-2"
                  >
                    Add Website
                  </Link>
                </Col>
              </Row>

              {websites.map((website, i) => (
                <Row className="mb-3" key={i}>
                  <Form.Group as={Col} lg={4}>
                    <Form.Control
                      as="select"
                      className="main"
                      value={websites[i].type}
                      onChange={(e) => {
                        onChangeWebsiteType(i, e.target.value);
                      }}
                    >
                      {websiteType.map((type, i) => (
                        <option key={i} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} lg={6}>
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="Website"
                      value={website.link}
                      onChange={(e) => onChangeWebsite(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Col lg={2} className="text-center">
                    <Link
                      onClick={(e) => onRemoveWebsite(website)}
                      className="btn btn-sm btn-danger"
                    >
                      Remove
                    </Link>
                  </Col>
                </Row>
              ))}

              <Row className="mb-3">
                <Col>
                  <Form.Label>Email</Form.Label>
                </Col>

                <Col className="text-right">
                  <Link onClick={onAddEmail} className="btn btn-sm btn-blue-2">
                    Add Email
                  </Link>
                </Col>
              </Row>

              {emails.map((email, i) => (
                <Row className="mb-3" key={i}>
                  <Form.Group as={Col} lg={4}>
                    <Form.Control
                      as="select"
                      className="main"
                      value={emails[i].type}
                      onChange={(e) => {
                        onChangeEmailType(i, e.target.value);
                      }}
                    >
                      {emailType.map((type, i) => (
                        <option key={i} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} lg={6}>
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="email"
                      value={email.email}
                      onChange={(e) => onChangeEmail(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Col lg={2} className="text-center">
                    <Link
                      onClick={(e) => onRemoveEmail(email)}
                      className="btn btn-sm btn-danger"
                    >
                      Remove
                    </Link>
                  </Col>
                </Row>
              ))}

              <Row className="mb-3">
                <Col>
                  <Form.Label>Phone</Form.Label>
                </Col>

                <Col className="text-right">
                  <Link onClick={onAddPhone} className="btn btn-sm btn-blue-2">
                    Add Phone
                  </Link>
                </Col>
              </Row>

              {phones.map((phone, i) => (
                <Row className="mb-3" key={i}>
                  <Form.Group as={Col} lg={4}>
                    <Form.Control
                      as="select"
                      className="main"
                      value={phones[i].type}
                      onChange={(e) => {
                        onChangePhoneType(i, e.target.value);
                      }}
                    >
                      {phoneType.map((type, i) => (
                        <option key={i} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} lg={6}>
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="phone"
                      value={phone.phone}
                      onChange={(e) => onChangePhone(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Col lg={2} className="text-center">
                    <Link
                      onClick={(e) => onRemovePhone(phone)}
                      className="btn btn-sm btn-danger"
                    >
                      Remove
                    </Link>
                  </Col>
                </Row>
              ))}

              <Row className="mb-3">
                <Col>
                  <Form.Label>Address</Form.Label>
                </Col>

                <Col className="text-right">
                  <Link
                    onClick={onAddAddress}
                    className="btn btn-sm btn-blue-2"
                  >
                    Add Address
                  </Link>
                </Col>
              </Row>

              {addresses.map((address, i) => (
                <Row className="mb-3" key={i}>
                  <Form.Group as={Col} lg={4}>
                    <Form.Control
                      as="select"
                      className="main"
                      value={addresses[i].type}
                      onChange={(e) => {
                        onChangeAddressType(i, e.target.value);
                      }}
                    >
                      {phoneType.map((type, i) => (
                        <option key={i} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Col lg={6}>
                    <Form.Group>
                      <Form.Control
                        className="main"
                        type="text"
                        placeholder="street"
                        value={address.street}
                        onChange={(e) => onChangeStreet(i, e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Control
                        className="main"
                        type="text"
                        placeholder="city"
                        value={address.city}
                        onChange={(e) => onChangeCity(i, e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Control
                        className="main"
                        type="text"
                        placeholder="state"
                        value={address.state}
                        onChange={(e) => onChangeState(i, e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Control
                        className="main"
                        type="text"
                        placeholder="country"
                        value={address.country}
                        onChange={(e) => onChangeCountry(i, e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Control
                        className="main"
                        type="text"
                        placeholder="zip"
                        value={address.zip}
                        onChange={(e) => onChangeZip(i, e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={2} className="text-center">
                    <Link
                      onClick={(e) => onRemoveAddress(address)}
                      className="btn btn-sm btn-danger"
                    >
                      Remove
                    </Link>
                  </Col>
                </Row>
              ))}

              <div className="title-text py-2">
                <h5 className="h5 font-weight-bold">
                  Social Media Information
                </h5>
              </div>

              <Row className="mb-3">
                <Form.Group as={Col} lg={6}>
                  <Form.Label>Skype</Form.Label>
                  <Form.Control
                    className="main"
                    type="text"
                    placeholder="@Skype"
                    value={skype}
                    onChange={(e) => setSkype(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={6}>
                  <Form.Label>Hangouts</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="@Hangouts"
                    value={hangouts}
                    onChange={(e) => setHangouts(e.target.value)}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} lg={6}>
                  <Form.Label>Twitter</Form.Label>
                  <Form.Control
                    className="main"
                    type="text"
                    placeholder="@Twitter"
                    value={twitter}
                    onChange={(e) => setTwitter(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={6}>
                  <Form.Label>LinkedIn</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="@LinkedIn"
                    value={linkedin}
                    onChange={(e) => setLinkedin(e.target.value)}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} lg={6}>
                  <Form.Label>Facebook</Form.Label>
                  <Form.Control
                    className="main"
                    type="text"
                    placeholder="@Facebook"
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={6}>
                  <Form.Label>Instagram</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="@Instagram"
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                  />
                </Form.Group>
              </Row>

              {/* {profileType == 6 ? (<> */}

              <Row className="mb-3">
                <Form.Group as={Col} lg={6}>
                  <Form.Label>Whatsapp Number</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="+91"
                    value={whatsappNumber}
                    onChange={(e) => setWhatsappNumber(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={6}>
                  <Form.Label>LinkedIn Company</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="@LinkedIn"
                    value={linkedinCompany}
                    onChange={(e) => setLinkedinCompany(e.target.value)}
                  />
                </Form.Group>
              </Row>

              {/* </>):(<></>)} */}

              <div className="title-text py-2">
                <h5 className="h5 font-weight-bold">Other Details</h5>
              </div>

              <Row className="mb-3">
                <Form.Group as={Col} lg={12}>
                  <Form.Label>Bio</Form.Label>
                  <Form.Control
                    className="main"
                    type="text"
                    as="textarea"
                    rows={3}
                    placeholder="Enter Bio"
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                  />
                </Form.Group>
              </Row>

              {/* {profileType == 6 ? (<> */}

              <Row className="mt-2 title-text">
                <Col>
                  <h5 className="h5 font-weight-bold">Business Details</h5>
                </Col>

                <Col></Col>

                <Col className="text-right">
                  <Form.Control
                    as="select"
                    className="main"
                    value={showBusinessDetails ? "Show" : "Hide"}
                    onChange={(e) => {
                      setShowBusinessDetails(e.target.value == "Show");
                    }}
                  >
                    <option value="Show">Show</option>

                    <option value="Hide">Hide</option>
                  </Form.Control>
                </Col>
              </Row>

              <div className="row">
                <Form.Group as={Col} lg={12}>
                  <Form.Label>Registered Company Name</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter Company Name"
                    value={regCompanyName}
                    onChange={(e) => setRegCompanyName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={12}>
                  <Form.Label>Registered Company Address</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter Company Address"
                    value={regCompanyAddress}
                    onChange={(e) => setRegCompanyAddress(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={12}>
                  <Form.Label>GST Number</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter GST Number"
                    value={gstNumber}
                    onChange={(e) => setGstNumber(e.target.value)}
                  />
                </Form.Group>

                {/*  <Form.Group as={Col} lg={6}>
                  <Form.Label>PAN Number</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter PAN Number"
                    value={panNumber}
                    onChange={(e) => setpanNumber(e.target.value)}
                  />
                </Form.Group> */}
              </div>

              <Row className="mt-2 title-text">
                <Col>
                  <h5 className="h5 font-weight-bold">Bank Details</h5>
                </Col>

                <Col></Col>

                <Col className="text-right">
                  <Form.Control
                    as="select"
                    className="main"
                    value={showBankDetails ? "Show" : "Hide"}
                    onChange={(e) => {
                      setShowBankDetails(e.target.value == "Show");
                    }}
                  >
                    <option value="Show">Show</option>

                    <option value="Hide">Hide</option>
                  </Form.Control>
                </Col>
              </Row>

              <div className="row">
                <Form.Group as={Col} lg={12}>
                  <Form.Label>A/C Type</Form.Label>
                  <Form.Control
                    as="select"
                    className="main"
                    value={acType}
                    onChange={(e) => {
                      setAcType(e.target.value);
                    }}
                  >
                    <option value="Current">Current</option>

                    <option value="Savings">Savings</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} lg={12}>
                  <Form.Label>A/C Name</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter A/C Name"
                    value={acName}
                    onChange={(e) => setAcName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={12}>
                  <Form.Label>A/C Number</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter A/C Number"
                    value={acNumber}
                    onChange={(e) => setAcNumber(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={12}>
                  <Form.Label>IFSC Code</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter IFSC Code"
                    value={ifscCode}
                    onChange={(e) => setIfscCode(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={12}>
                  <Form.Label>Bank Name</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter Bank Name"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={12}>
                  <Form.Label>Branch Name</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter Branch Name"
                    value={branchName}
                    onChange={(e) => setBranchName(e.target.value)}
                  />
                </Form.Group>
              </div>

              {/*  </>):(<></>)} */}
            </>
          ) : (
            <></>
          )}

          <div className="text-right">
            <Link onClick={onShowMore} className="btn btn-sm btn-blue-2">
              {!showMore ? "Show More" : "Show Less"}
            </Link>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="title-text py-2 mb-4">
            <h5 className="h5 font-weight-bold">
              {profileTypeList[profileType].name}
            </h5>
            <p>Enter the details below to create your card profile.</p>
          </div>

          <Row className="mb-3">
            <Form.Group as={Col} lg={12}>
              <Form.Label>Profile Name</Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Profile Name"
                value={profileName}
                onChange={(e) => setProfileName(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={12}>
              <Form.Label className="required-field">
                {profileTypeList[profileType].inputType}
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder={profileTypeList[profileType].hint}
                value={link}
                onChange={(e) => setLink(e.target.value)}
                required
              />
            </Form.Group>
          </Row>
        </>
      );
    }
  }

  function formBlock() {
    if (profileType == 0) {
      return (
        <>
          <div className="title-text py-2">
            <h5 className="h5 font-weight-bold">Personal Information</h5>
          </div>

          <Row className="mb-3">
            <Col lg={4} className="text-center">
              <img
                onClick={upload}
                className="img img-fluid avatar"
                src={
                  profileImage != ""
                    ? profileImage
                    : "https://dashboard.tap1ce.com/media/avatar.png"
                }
              ></img>
              <input
                id="selectFile"
                type="file"
                hidden
                onChange={fileChangedHandler}
                onClick={fileClickHandler}
              />
            </Col>

            <Form.Group as={Col} lg={8}>
              <Form.Label>
                Profile Name{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      This is visible only to you for labeling your profiles and
                      ease of switching in profiles.{" "}
                    </Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Profile Name"
                value={profileName}
                onChange={(e) => setProfileName(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={4} controlId="formName">
              <Form.Label>
                Prefix{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Mr / Ms / Mrs.. </Tooltip>}
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Enter Prefix"
                value={prefix}
                onChange={(e) => setPrefix(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} lg={8} controlId="formPhone">
              <Form.Label className="required-field">
                Name{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>Name you want contact to be saved as</Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                placeholder="Enter Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={6} controlId="formCompany">
              <Form.Label className={profileType == 0 ? "required-field" : ""}>
                Company / Organization{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Name of your organization</Tooltip>}
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Enter Company Name"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                required={profileType == 0}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="formDesignation">
              <Form.Label className={profileType == 0 ? "required-field" : ""}>
                Designation / Role / Title{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Your role in the organization</Tooltip>}
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Enter Designation"
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
                required={profileType == 0}
              />
            </Form.Group>
          </Row>

          {showMore ? (
            <>
              <div className="title-text py-2">
                <h5 className="h5 font-weight-bold">Contact Information</h5>
              </div>

              <Row className="mb-3">
                <Col>
                  <Form.Label>
                    Website{" "}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip>
                          Your website url copied from the web browser.
                          Including "https//:"
                        </Tooltip>
                      }
                    >
                      <i
                        className="fa fa-info-circle text-primary"
                        aria-hidden="true"
                      ></i>
                    </OverlayTrigger>
                  </Form.Label>
                </Col>

                <Col className="text-right">
                  <Link
                    onClick={onAddWebsite}
                    className="btn btn-sm btn-blue-2"
                  >
                    Add Website
                  </Link>
                </Col>
              </Row>

              {websites.map((website, i) => (
                <Row className="mb-3" key={i}>
                  <Form.Group as={Col} lg={4}>
                    <Form.Control
                      as="select"
                      className="main"
                      value={websites[i].type}
                      onChange={(e) => {
                        onChangeWebsiteType(i, e.target.value);
                      }}
                    >
                      {websiteType.map((type, i) => (
                        <option key={i} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} lg={6}>
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="Website"
                      value={website.link}
                      onChange={(e) => onChangeWebsite(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Col lg={2} className="text-center">
                    <Link
                      onClick={(e) => onRemoveWebsite(website)}
                      className="btn btn-sm btn-danger"
                    >
                      Remove
                    </Link>
                  </Col>
                </Row>
              ))}

              <Row className="mb-3">
                <Col>
                  <Form.Label>Email</Form.Label>
                </Col>

                <Col className="text-right">
                  <Link onClick={onAddEmail} className="btn btn-sm btn-blue-2">
                    Add Email
                  </Link>
                </Col>
              </Row>

              {emails.map((email, i) => (
                <Row className="mb-3" key={i}>
                  <Form.Group as={Col} lg={4}>
                    <Form.Control
                      as="select"
                      className="main"
                      value={emails[i].type}
                      onChange={(e) => {
                        onChangeEmailType(i, e.target.value);
                      }}
                    >
                      {emailType.map((type, i) => (
                        <option key={i} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} lg={6}>
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="email"
                      value={email.email}
                      onChange={(e) => onChangeEmail(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Col lg={2} className="text-center">
                    <Link
                      onClick={(e) => onRemoveEmail(email)}
                      className="btn btn-sm btn-danger"
                    >
                      Remove
                    </Link>
                  </Col>
                </Row>
              ))}

              <Row className="mb-3">
                <Col>
                  <Form.Label>Phone</Form.Label>
                </Col>

                <Col className="text-right">
                  <Link onClick={onAddPhone} className="btn btn-sm btn-blue-2">
                    Add Phone
                  </Link>
                </Col>
              </Row>

              {phones.map((phone, i) => (
                <Row className="mb-3" key={i}>
                  <Form.Group as={Col} lg={4}>
                    <Form.Control
                      as="select"
                      className="main"
                      value={phones[i].type}
                      onChange={(e) => {
                        onChangePhoneType(i, e.target.value);
                      }}
                    >
                      {phoneType.map((type, i) => (
                        <option key={i} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} lg={6}>
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="phone"
                      value={phone.phone}
                      onChange={(e) => onChangePhone(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Col lg={2} className="text-center">
                    <Link
                      onClick={(e) => onRemovePhone(phone)}
                      className="btn btn-sm btn-danger"
                    >
                      Remove
                    </Link>
                  </Col>
                </Row>
              ))}

              <Row className="mb-3">
                <Col>
                  <Form.Label>
                    Address{" "}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip>Your detailed address</Tooltip>}
                    >
                      <i
                        className="fa fa-info-circle text-primary"
                        aria-hidden="true"
                      ></i>
                    </OverlayTrigger>
                  </Form.Label>
                </Col>

                <Col className="text-right">
                  <Link
                    onClick={onAddAddress}
                    className="btn btn-sm btn-blue-2"
                  >
                    Add Address
                  </Link>
                </Col>
              </Row>

              {addresses.map((address, i) => (
                <Row className="mb-3" key={i}>
                  <Form.Group as={Col} lg={4}>
                    <Form.Control
                      as="select"
                      className="main"
                      value={addresses[i].type}
                      onChange={(e) => {
                        onChangeAddressType(i, e.target.value);
                      }}
                    >
                      {phoneType.map((type, i) => (
                        <option key={i} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Col lg={6}>
                    <Form.Group>
                      <Form.Control
                        className="main"
                        type="text"
                        placeholder="street"
                        value={address.street}
                        onChange={(e) => onChangeStreet(i, e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Control
                        className="main"
                        type="text"
                        placeholder="city"
                        value={address.city}
                        onChange={(e) => onChangeCity(i, e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Control
                        className="main"
                        type="text"
                        placeholder="state"
                        value={address.state}
                        onChange={(e) => onChangeState(i, e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Control
                        className="main"
                        type="text"
                        placeholder="country"
                        value={address.country}
                        onChange={(e) => onChangeCountry(i, e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Control
                        className="main"
                        type="text"
                        placeholder="zip"
                        value={address.zip}
                        onChange={(e) => onChangeZip(i, e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={2} className="text-center">
                    <Link
                      onClick={(e) => onRemoveAddress(address)}
                      className="btn btn-sm btn-danger"
                    >
                      Remove
                    </Link>
                  </Col>
                </Row>
              ))}

              <div className="title-text py-2">
                <h5 className="h5 font-weight-bold">
                  Social Media Information
                </h5>
              </div>

              <Row className="mb-3">
                <Form.Group as={Col} lg={6}>
                  <Form.Label>
                    Skype{" "}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip>Your Skype user name. "@username"</Tooltip>
                      }
                    >
                      <i
                        className="fa fa-info-circle text-primary"
                        aria-hidden="true"
                      ></i>
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    className="main"
                    type="text"
                    placeholder="@Skype"
                    value={skype}
                    onChange={(e) => setSkype(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={6}>
                  <Form.Label>
                    Hangouts{" "}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip>Your Hangouts user name. "username"</Tooltip>
                      }
                    >
                      <i
                        className="fa fa-info-circle text-primary"
                        aria-hidden="true"
                      ></i>
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="@Hangouts"
                    value={hangouts}
                    onChange={(e) => setHangouts(e.target.value)}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} lg={6}>
                  <Form.Label>
                    Twitter{" "}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip>Your Twitter user name. "@username"</Tooltip>
                      }
                    >
                      <i
                        className="fa fa-info-circle text-primary"
                        aria-hidden="true"
                      ></i>
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    className="main"
                    type="text"
                    placeholder="@Twitter"
                    value={twitter}
                    onChange={(e) => setTwitter(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={6}>
                  <Form.Label>
                    LinkedIn{" "}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip>Your Linkedin user name. "@username"</Tooltip>
                      }
                    >
                      <i
                        className="fa fa-info-circle text-primary"
                        aria-hidden="true"
                      ></i>
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="@LinkedIn"
                    value={linkedin}
                    onChange={(e) => setLinkedin(e.target.value)}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} lg={6}>
                  <Form.Label>
                    Facebook{" "}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip>Your Facebook user name. "@username"</Tooltip>
                      }
                    >
                      <i
                        className="fa fa-info-circle text-primary"
                        aria-hidden="true"
                      ></i>
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    className="main"
                    type="text"
                    placeholder="@Facebook"
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={6}>
                  <Form.Label>
                    Instagram{" "}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip>Your Instagram user name. "@username"</Tooltip>
                      }
                    >
                      <i
                        className="fa fa-info-circle text-primary"
                        aria-hidden="true"
                      ></i>
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="@Instagram"
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                  />
                </Form.Group>
              </Row>

              {/* {profileType == 6 ? (<> */}

              <Row className="mb-3">
                <Form.Group as={Col} lg={6}>
                  <Form.Label>
                    Whatsapp Number{" "}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip>
                          Your WhatsApp number. "+91 Your-10-digit-number "
                        </Tooltip>
                      }
                    >
                      <i
                        className="fa fa-info-circle text-primary"
                        aria-hidden="true"
                      ></i>
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="+91"
                    value={whatsappNumber}
                    onChange={(e) => setWhatsappNumber(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={6}>
                  <Form.Label>
                    LinkedIn Company{" "}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip>
                          Your Linkedin Company user name. "@username"
                        </Tooltip>
                      }
                    >
                      <i
                        className="fa fa-info-circle text-primary"
                        aria-hidden="true"
                      ></i>
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="@LinkedIn Company"
                    value={linkedinCompany}
                    onChange={(e) => setLinkedinCompany(e.target.value)}
                  />
                </Form.Group>
              </Row>

              {/* </>):(<></>)} */}

              <div className="title-text py-2">
                <h5 className="h5 font-weight-bold">Other Details</h5>
              </div>

              <Row className="mb-3">
                <Form.Group as={Col} lg={12}>
                  <Form.Label>
                    Bio{" "}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip>
                          Any additional text details you would like to add
                        </Tooltip>
                      }
                    >
                      <i
                        className="fa fa-info-circle text-primary"
                        aria-hidden="true"
                      ></i>
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    className="main"
                    type="text"
                    as="textarea"
                    rows={3}
                    placeholder="Enter Bio"
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                  />
                </Form.Group>
              </Row>

              {/* {profileType == 6 ? (<> */}

              <Row className="mt-2 title-text">
                <Col>
                  <h5 className="h5 font-weight-bold">Business Details</h5>
                </Col>

                <Col></Col>

                <Col className="text-right">
                  <Form.Control
                    as="select"
                    className="main"
                    value={showBusinessDetails ? "Show" : "Hide"}
                    onChange={(e) => {
                      setShowBusinessDetails(e.target.value == "Show");
                    }}
                  >
                    <option value="Show">Show</option>

                    <option value="Hide">Hide</option>
                  </Form.Control>
                </Col>
              </Row>

              <div className="row">
                <Form.Group as={Col} lg={12}>
                  <Form.Label>Registered Company Name</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter Company Name"
                    value={regCompanyName}
                    onChange={(e) => setRegCompanyName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={12}>
                  <Form.Label>Registered Company Address</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter Company Address"
                    value={regCompanyAddress}
                    onChange={(e) => setRegCompanyAddress(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={12}>
                  <Form.Label>GST Number</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter GST Number"
                    value={gstNumber}
                    onChange={(e) => setGstNumber(e.target.value)}
                  />
                </Form.Group>

                {/*  <Form.Group as={Col} lg={6}>
                  <Form.Label>PAN Number</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter PAN Number"
                    value={panNumber}
                    onChange={(e) => setpanNumber(e.target.value)}
                  />
                </Form.Group> */}
              </div>

              <Row className="mt-2 title-text">
                <Col>
                  <h5 className="h5 font-weight-bold">Bank Details</h5>
                </Col>

                <Col></Col>

                <Col className="text-right">
                  <Form.Control
                    as="select"
                    className="main"
                    value={showBankDetails ? "Show" : "Hide"}
                    onChange={(e) => {
                      setShowBankDetails(e.target.value == "Show");
                    }}
                  >
                    <option value="Show">Show</option>

                    <option value="Hide">Hide</option>
                  </Form.Control>
                </Col>
              </Row>

              <div className="row">
                <Form.Group as={Col} lg={12}>
                  <Form.Label>A/C Type</Form.Label>
                  <Form.Control
                    as="select"
                    className="main"
                    value={acType}
                    onChange={(e) => {
                      setAcType(e.target.value);
                    }}
                  >
                    <option value="Current">Current</option>

                    <option value="Savings">Savings</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} lg={12}>
                  <Form.Label>A/C Name</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter A/C Name"
                    value={acName}
                    onChange={(e) => setAcName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={12}>
                  <Form.Label>A/C Number</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter A/C Number"
                    value={acNumber}
                    onChange={(e) => setAcNumber(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={12}>
                  <Form.Label>IFSC Code</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter IFSC Code"
                    value={ifscCode}
                    onChange={(e) => setIfscCode(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={12}>
                  <Form.Label>Bank Name</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter Bank Name"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={12}>
                  <Form.Label>Branch Name</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter Branch Name"
                    value={branchName}
                    onChange={(e) => setBranchName(e.target.value)}
                  />
                </Form.Group>
              </div>

              {/*  </>):(<></>)} */}
            </>
          ) : (
            <></>
          )}

          <div className="text-right">
            <Link onClick={onShowMore} className="btn btn-sm btn-blue-2">
              {!showMore ? "Show More" : "Show Less"}
            </Link>
          </div>
        </>
      );
    } else if (profileType == 6) {
      return (
        <>
          <div className="title-text py-2">
            <h5 className="h5 font-weight-bold">1 Link Information</h5>
          </div>

          <Row className="mb-3">
            <Col lg={4} className="text-center">
              <img
                onClick={upload}
                className="img img-fluid avatar"
                src={
                  profileImage != ""
                    ? profileImage
                    : "https://dashboard.tap1ce.com/media/avatar.png"
                }
              ></img>
              <input
                id="selectFile"
                type="file"
                hidden
                onChange={fileChangedHandler}
                onClick={fileClickHandler}
              />
            </Col>

            <Form.Group as={Col} lg={8}>
              <div className="input-group mb-4">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <small>https://1link.tap1ce.com/</small>
                  </span>
                </div>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon2"
                  value={username}
                  //pattern={/^[a-z0-9_\.]+$/}
                  //pattern="/^[a-z0-9_\.]+$/"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>

              <Form.Label>
                Title{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      This is visible only to you for labeling your 1 Link.{" "}
                    </Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Title"
                value={oneLinkTitle}
                onChange={(e) => setOneLinkTitle(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={12}>
              <Form.Label>
                About{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      Any additional text details you would like to add
                    </Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                as="textarea"
                rows={3}
                placeholder="Enter about"
                value={oneLinkAbout}
                onChange={(e) => setOneLinkAbout(e.target.value)}
              />
            </Form.Group>
          </Row>

          <div className="title-text py-2">
            <h5 className="h5 font-weight-bold">Social Media Information</h5>
          </div>

          <Row className="mb-3">
            <Form.Group as={Col} lg={6}>
              <Form.Label>
                Skype{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Your Skype user name. "@username"</Tooltip>}
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="@Skype"
                value={skype}
                onChange={(e) => setSkype(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Form.Label>
                Hangouts{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>Your Hangouts user name. "username"</Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                placeholder="@Hangouts"
                value={hangouts}
                onChange={(e) => setHangouts(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={6}>
              <Form.Label>
                Twitter{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>Your Twitter user name. "@username"</Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="@Twitter"
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Form.Label>
                LinkedIn{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>Your Linkedin user name. "@username"</Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                placeholder="@LinkedIn"
                value={linkedin}
                onChange={(e) => setLinkedin(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={6}>
              <Form.Label>
                Facebook{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>Your Facebook user name. "@username"</Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="@Facebook"
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Form.Label>
                Instagram{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>Your Instagram user name. "@username"</Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                placeholder="@Instagram"
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={6}>
              <Form.Label>
                Whatsapp Number{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      Your WhatsApp number. "+91 Your-10-digit-number "
                    </Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                placeholder="+91"
                value={whatsappNumber}
                onChange={(e) => setWhatsappNumber(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Form.Label>
                LinkedIn Company{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      Your Linkedin Company user name. "@username"
                    </Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                placeholder="@LinkedIn Company"
                value={linkedinCompany}
                onChange={(e) => setLinkedinCompany(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Label>
                Others Links{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      Your website url copied from the web browser. Including
                      "https//:"
                    </Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
            </Col>

            <Col className="text-right">
              <Link onClick={onAdd1Link} className="btn btn-sm btn-blue-2">
                Add Link
              </Link>
            </Col>
          </Row>

          {oneLinks.map((website, i) => (
            <Row className="mb-3" key={i}>
              <Form.Group as={Col} lg={4}>
                <Form.Control
                  className="main"
                  type="text"
                  placeholder="Link Title"
                  value={website.title}
                  onChange={(e) => onChange1LinkTitle(i, e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} lg={6}>
                <Form.Control
                  className="main"
                  type="text"
                  placeholder="Url"
                  value={website.link}
                  onChange={(e) => onChange1LinkUrl(i, e.target.value)}
                  required
                />
              </Form.Group>

              <Col lg={2} className="text-center">
                <Link
                  onClick={(e) => onRemove1Link(website)}
                  className="btn btn-sm btn-danger"
                >
                  Remove
                </Link>
              </Col>
            </Row>
          ))}
        </>
      );
    } else {
      return (
        <>
          <div className="title-text py-2 mb-4">
            <h5 className="h5 font-weight-bold">
              {profileTypeList[profileType].name}
            </h5>
            <p>Enter the details below to create your card profile.</p>
          </div>

          <Row className="mb-3">
            <Form.Group as={Col} lg={12}>
              <Form.Label>Profile Name</Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Profile Name"
                value={profileName}
                onChange={(e) => setProfileName(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={12}>
              <Form.Label className="required-field">
                {profileTypeList[profileType].inputType}
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder={profileTypeList[profileType].hint}
                value={link}
                onChange={(e) => setLink(e.target.value)}
                required
              />
            </Form.Group>
          </Row>
        </>
      );
    }
  }

  const onAddProfile = async (e) => {
    e.preventDefault();
    setProgress(true);

    const data = {
      //id:card.activeProfileId,
      card_id: match.params.id,
      typeId: profileType,
      type: profileTypeList[profileType].type,
      link: link,

      profile_name: profileName,

      name_prefix: prefix,
      name: name,
      company: company,
      designation: designation,
      websites: websites,
      emails: emails,
      phones: phones,
      addresses: addresses,
      bio: bio,

      profile: profileImage,
      reg_company_name: regCompanyName,
      reg_company_address: regCompanyAddress,
      gst_number: gstNumber,
      pan_number: panNumber,
      whatsapp_number: whatsappNumber,
      showBankDetails: showBankDetails,
      showBusinessDetails: showBusinessDetails,

      theme: theme,

      bank: {
        acType: acType,
        acName: acName,
        acNumber: acNumber,
        ifscCode: ifscCode,
        bankName: bankName,
        branchName: branchName,
      },

      //oneLink:{
      username: username,
      title: oneLinkTitle,
      about: oneLinkAbout,
      other_links: oneLinks,
      //}

      social_accounts: [
        {
          type: "skype",
          link: skype,
        },
        {
          type: "hangouts",
          link: hangouts,
        },
        {
          type: "twitter",
          link: twitter,
        },
        {
          type: "linkedin",
          link: linkedin,
        },
        {
          type: "linkedinCompany",
          link: linkedinCompany,
        },
        {
          type: "facebook",
          link: facebook,
        },
        {
          type: "instagram",
          link: instagram,
        },
      ],
    };

    return dispatch(addProfile(data));
  };

  return (
    <AddProfileContainer>
      <section className="section">
        <div className="container">
          <>
            <div className="page-title-section">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Add Profile</Breadcrumb.Item>
              </Breadcrumb>

              <div className="row">
                <div className="col-lg-12">
                  <h3 className="title">Add Profile</h3>
                </div>

                {profileType == 0 ? (
                  <div className="col-lg-4">
                    <div className="theme-switch-wrapper">
                      <label className="theme-switch" for="checkbox">
                        <input
                          type="checkbox"
                          checked={theme == "dark"}
                          id="checkbox"
                          onChange={(e) => {
                            setTheme(theme == "light" ? "dark" : "light");
                          }}
                        />
                        <div className="theme-slider round"></div>
                      </label>
                      <em>
                        {theme == "dark" ? "Dark Mode" : "Light Mode"}
                        {"  "}
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip>
                              This theme will shown when you share your contact.
                            </Tooltip>
                          }
                        >
                          <i
                            className="fa fa-info-circle text-primary"
                            aria-hidden="true"
                          ></i>
                        </OverlayTrigger>
                      </em>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <section className="section">
              {/* <Row className="p-4 ">
                {profileTypeList.map((pt, i) => (
                  <Col
                    lg={2}
                    md={3}
                    sm={4}
                    xs={6}
                    key={i}
                    className="text-center"
                  >
                    <Button
                      size="sm"
                      className="mb-2 btn-block"
                      variant={profileType == i ? "primary" : "outline-primary"}
                      onClick={(e) => onChangeProfileType(i)}
                    >
                      {pt.name}
                    </Button>
                  </Col>
                ))}
              </Row> */}

              <Row>
                <Col lg={4}>
                  <div>
                    <Card className="mt-2">
                      <Card.Img variant="top" src={cardBG} />

                      <div className={"card-taps text-left"}>
                        <h2 className={"h6 font-weight-bold mb-0 text-white"}>
                          {profileName}
                        </h2>
                      </div>

                      <div className={"card-content text-left text-white"}>
                        <h2 className={"h6 font-weight-bold mb-0 text-white"}>
                          <i className={profileTypeList[profileType].icon}></i>
                          &nbsp;
                          {profileTypeList[profileType].type === "business"
                            ? name === ""
                              ? "Your Name"
                              : prefix + " " + name
                            : profileTypeList[profileType].name}
                        </h2>
                        <p
                          className={"text-small sort-link marquee text-white"}
                        >
                          <span>
                            {profileTypeList[profileType].type === "business"
                              ? company
                              : link}
                          </span>
                        </p>
                      </div>
                    </Card>
                  </div>
                </Col>

                <Col lg={6}>
                  <Row className="mt-2 justify-content-center p-2">
                    {profileTypeList.map((pt, i) =>
                      (pt.type == "1link" && !(profile?.oneLink ?? true)) ||
                      (!pt.enable && !(profile?.oneLink ?? true)) ? (
                        <></>
                      ) : (
                        <Col
                          lg={4}
                          md={4}
                          sm={4}
                          xs={4}
                          key={i}
                          className="text-center mb-2 px-2"
                        >
                          <label key={i} className="profile-label">
                            <input
                              type="radio"
                              name="profile"
                              className="profile-type-input"
                              id={i}
                              checked={profileType == i ? true : false}
                              onChange={(e) => {
                                console.log("card change ", e.target.value);
                                onChangeProfileType(i);
                              }}
                            />

                            <div className="card profile-type p-2 text-left">
                              <i
                                className={profileTypeList[i].icon + " bg-icon"}
                              ></i>

                              <i className={profileTypeList[i].icon}></i>

                              <h2 className="h6 font-weight-bold mt-auto profile-name">
                                {profileTypeList[i].name}
                              </h2>

                              <div className="check-item">
                                <div className=" float-left">
                                  <span className="check-icon"></span>
                                </div>
                              </div>
                            </div>
                          </label>
                        </Col>
                      )
                    )}
                  </Row>

                  <Form className="py-2 mt-2" onSubmit={onAddProfile}>
                    {formBlock()}

                    <Row>
                      <Col className="col-12 text-center">
                        {progress ? (
                          <Spinner animation="border" variant="primary" />
                        ) : (
                          <Button
                            variant="primary"
                            type="submit"
                            className="btn btn-lg btn-primary"
                          >
                            Add Profile
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </section>
          </>
        </div>
      </section>
    </AddProfileContainer>
  );
};

export default AddProfilePage;
